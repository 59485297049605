import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import face from "../../Assets/about.png";
import Socials from "../../components/Footer";

function About() {
  return (
    <Container fluid className="about-section">
      <Container style={{ width: "90%" }}>
        <Row style={{ justifyContent: "center", paddingTop: "3vh" }}>
          <Col
            sm={12}
            md={5}
            style={{
              justifyContent: "center",
            }}
          >
            <h1 style={{ fontSize: "1em", fontWeight: 300, paddingBottom: "20px" }}>
              I am a machine learning engineer at Apple working on Siri Knowledge
              question answering and entity linking. My fascination with the intersection of computer science
              and visual arts means I'm excited by different opportunities to create- whether it's working on
              research problems, in the kitchen, building products, or painting on a canvas.<br />
              <br />
              Back in college I started a molecular engineering major, excited about nanoparticle materials. Since
              then, my research has changed a lot: I was way more interested in high performance computing and maths
              that model physical and biological processes, which led me to computer science, statistics,
              and eventually machine learning and AI. <br /><br />
              I've worked on CV/graphics problems at Apple, researched adversarial machine learning
              at the SAND Lab (advised by{" "} <a id="link" href="http://people.cs.uchicago.edu/~ravenben/">Ben Zhao</a>),
              and started the{" "}
              <a id="link" href="https://www.linkedin.com/posts/anachavarria_aiml-rotation-program-summer-2024-careers-activity-7117988483117318144-vssW/?trk=public_profile_like_view">AI/ML rotation program</a> in January 2022. <br /><br />

              As a Canadian-Chinese who grew up going to American schools, I cook fusion food,
              love travelling, and am bilingual but sometimes forget both languages.
              I also love to: <br />
              <ul style={{ listStylePosition: "inside" }}>
                <li>
                  host dinner parties & <a href="/bite-photography">design around food</a>
                </li>
                <li>
                  oil paint & take photos
                </li>
                <li>
                  hang out with my pup Harper
                </li>
              </ul>

            </h1>
          </Col>
          <Col
            sm={12}
            md={4}
            style={{}}
            className="about-img"
          >
            <img src={face} alt="about" className="img-fluid" style={{ maxHeight: "25em", paddingLeft: "20%" }} />
          </Col>
        </Row>

      </Container>
    </Container>
  );
}

export default About;
